<!-- 联系我们 -->
<template>
  <div class="contactUs">
    <a href="https://wpa1.qq.com/gqag0O2y?_type=wpa&qidian=true" class="jumpQQ" ref="jumpQQ"></a>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  components: {},

  mounted() {
    this.$router.push({
      path: '/cardRecycle'
    })
    window.open('https://wpa1.qq.com/gqag0O2y?_type=wpa&qidian=true')
  },

  methods: {},
};
</script>
<style lang="scss" scoped>
.contactUs {
  width: 100%;
  height: 600px;

  .jumpQQ {
    display: none;
  }
}
</style>